import React from "react"
import { graphql } from "gatsby"
import Layout from "../layout/index"
import "./calendarTemplate.scss"
import SectionHeader from "../components/SectionHeader/SectionHeader"

export default function Calendar({
    data, // this prop will be injected by the GraphQL query below.
  }) {
    const { markdownRemark } = data // data.markdownRemark holds your post data
    const { frontmatter, html } = markdownRemark
    return (
      <Layout>
      <div className="blog-post-container">
      <SectionHeader className="blog-post-pageTitle">Latest Team Max News</SectionHeader>
        <div className="blog-post">
          {frontmatter?.featured && <img className="blog-post-featured" src={frontmatter.featured.publicURL} alt={frontmatter.title} />}
          <h3>{frontmatter.title}</h3>
          <p>{frontmatter.date}</p>
          <div
            className="blog-post-content"
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </div>
      </div>
      </Layout>
    )
  }
  export const pageQuery = graphql`
    query($path: String!) {
      markdownRemark(frontmatter: { path: { eq: $path } }) {
        html
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          path
          title
          featured {
            publicURL
          }
        }
      }
    }
  `